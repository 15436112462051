<template>
  <div class="container">
    <!-- begin::Serial -->
    <div class="row">
      <div class="col-12 mt-3 col-md-4 border-bottom form-group">
        <label class="text-upper">Serialnumber</label>
        <div class="row">
          <!-- TODO: Show serial_submanufacturer if set and you don't have the permission to see the 'normal' one -->
          <h5 class="col overflow-ellipsis">{{ equipment.serial }}</h5>
          <div
            v-if="$auth.hasPermission('equipment.update') || $auth.hasPermission('equipment.update.specifications')"
            class="col-auto cursor-pointer"
            @click.prevent="modifySpecification('serial', {
              title: 'Modify serialnumber',
              label: 'Serialnumber',
              required: true,
              validators: {
                serial: isSunbedSerial,
              }
            })"
          >
            <i class="text-dark">
              <font-awesome-icon :icon="['fas', 'pencil']" />
            </i>
          </div>
        </div>
      </div>

      <!-- TODO: Hide if you don't have the right permissions -->
      <div class="col-12 mt-3 col-md-4 border-bottom form-group">
        <label class="text-upper">Submanufacturer serial</label>
        <div class="row">
          <h5 class="col overflow-ellipsis">{{ (equipment.serial_submanufacturer || '-') }}</h5>
          <div
            v-if="$auth.hasPermission('equipment.update') || $auth.hasPermission('equipment.update.specifications')"
            class="col-auto cursor-pointer"
            @click.prevent="modifySpecification('serial_submanufacturer', {
              title: 'Modify submanufacturer serialnumber',
              label: 'Submanufacturer serialnumber'
            })"
          >
            <i class="text-dark">
              <font-awesome-icon :icon="['fas', 'pencil']" />
            </i>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3 col-md-4 border-bottom form-group">
        <label class="text-upper">Production date</label>
        <div class="row">
          <h5 class="col overflow-ellipsis">{{ $moment(equipment._meta.created).format('LL') }}</h5>
        </div>
      </div>
    </div>
    <!-- end::Serial -->

    <!-- begin::Specifications -->
    <div class="row row-cols-1 row-cols-md-3">
      <div class="col mt-3 border-bottom form-group">
        <label class="text-upper">Model</label>
        <div class="row">
          <h5 class="col overflow-ellipsis">{{ ($tu(`sunbeds.models.${equipment.model}.name`) || equipment.model || '-') }}</h5>
          <div
            v-if="$auth.hasPermission('equipment.update') || $auth.hasPermission('equipment.update.specifications')"
            class="col-auto cursor-pointer"
            @click.prevent="modifySunbedSpecifications()"
          >
            <i class="text-dark">
              <font-awesome-icon :icon="['fas', 'pencil']" />
            </i>
          </div>
        </div>
      </div>

      <div class="col mt-3 border-bottom form-group">
        <label class="text-upper">Type</label>
        <div class="row">
          <h5 class="col overflow-ellipsis">{{ ($tu(`sunbeds.models.${equipment.model}.types.${equipment.type}`) || equipment.type || '-') }}</h5>
          <div
            v-if="$auth.hasPermission('equipment.update') || $auth.hasPermission('equipment.update.specifications')"
            class="col-auto cursor-pointer"
            @click.prevent="modifySunbedSpecifications()"
          >
            <i class="text-dark">
              <font-awesome-icon :icon="['fas', 'pencil']" />
            </i>
          </div>
        </div>
      </div>

      <div class="col mt-3 border-bottom form-group">
        <label class="text-upper">Color</label>
        <div class="row">
          <h5 class="col overflow-ellipsis">{{ (equipment.getColorOfLocation('sunbed') || '-') }}</h5>
          <div
            v-if="$auth.hasPermission('equipment.update') || $auth.hasPermission('equipment.update.specifications')"
            class="col-auto cursor-pointer"
            @click.prevent="modifySunbedSpecifications()"
          >
            <i class="text-dark">
              <font-awesome-icon :icon="['fas', 'pencil']" />
            </i>
          </div>
        </div>
      </div>
    </div>
    <!-- end::Specifications -->

    <!-- begin::Specifications - Options -->
    <div class="row">
      <div class="col mt-3 border-bottom form-group">
        <label class="text-upper">Configuration</label>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          <!-- begin::No options -->
          <template v-if="!equipment.specifications.options || equipment.specifications.options.length === 0">
            <h5 class="col overflow-ellipsis">-</h5>
          </template>
          <!-- end::No options -->

          <!-- begin::Options -->
          <template
            v-else
            v-for="option in equipment.specifications.options"
          >
            <h5 class="col overflow-ellipsis" :key="`equipment.${equipment._meta.guid}.options.${option}`">{{ $tu(`sunbeds.models.${equipment.model}.options.${option}`) || $tu(`sunbeds.models.${equipment.model}.filters.${option}`) || option }}</h5>
          </template>
          <!-- end::Options -->
        </div>
      </div>
    </div>
    <!-- end::Specifications - Options -->

    <!-- begin::Linked companies -->
    <template v-if="$auth.hasPermission('companies.get')">
      <linked-companies
        :equipment="equipment" class="row position-relative min-h-100px"
        :transform="transformCompanies"
      >
        <template slot="default" slot-scope="props">
          <div class="col mt-3 form-group">
            <label class="text-upper mb-3">Linked companies</label>

            <!-- begin::Linked companies - company -->
            <div v-if="props.companies && props.companies.length > 0" class="row row-cols-1 row-cols-md-3">
              <div v-for="company in props.companies" :key="`equipment.${equipment._meta.guid}.companies.${company._meta.guid}`" class="col">
                <div class="card card-custom card-stretch gutter-b shadow">
                  <!--begin::Body-->
                  <div class="card-body d-flex">
                    <div class="flex-shrink-0 mr-7 my-auto">
                      <div class="symbol symbol-50">
                        <span class="symbol-label bg-none">
                          <i class="h-100 w-100">
                            <font-awesome-icon :icon="['fas', 'store-alt']" fixed-width class="w-100 h-100" />
                          </i>
                        </span>
                      </div>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5 my-auto">
                      <div class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-0">{{ company.name }}</div>
                      <span class="font-weight-bold text-muted font-size-lg">{{ $tu(`companies.type.${company.company_type}.name`) || company.company_type }}</span>
                      <span v-if="company.location && company.location.description" class="font-weight-bold text-muted font-size-lg">{{ company.location.description }}</span>
                    </div>
                  </div>
                  <!--end::Body-->
                </div>
              </div>
            </div>
            <!-- end::Linked companies - company -->

            <!-- begin::Linked companes - no company found -->
            <div v-else class="col alert alert-outline-info alert-custom">
              <div class="alert-icon">
                <i>
                  <font-awesome-icon :icon="['fas', 'store-alt']" />
                </i>
              </div>
              <div class="alert-text">
                <span class="font-weight-bold d-block">No companies linked</span>
                <span class="d-block" />
              </div>
            </div>
            <!-- end::Linked companies - no company found -->
          </div>
        </template>
      </linked-companies>
    </template>
    <!-- end::Linked companies -->
  </div>
</template>

<script>
import objectPath from 'object-path';
import { mutations as mutationsLib } from '@vedicium/core-vue';
// import { company as companyLib } from '@vedicium/core-vue/build/modules/company';
import { isSunbedSerial } from '@/libs/validators';

import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';

import Equipment from '@/libs/classes/equipment';

import linkedCompanies from '@/components/pages/sunbeds/view/components/linked-companies.vue';

export default {
  mixins: [equipmentMixin],
  components: {
    linkedCompanies,
  },
  data () {
    return {
      isSunbedSerial,
    };
  },
  methods: {
    modifySpecification (path = null, options = {}) {
      if (!path) {
        return;
      }

      const equipment = this.equipment.clone();
      const vm = this;
      vm.$eventhub.emit('modals:layout:document:modify-field:open', {
        ...(options || {}),
        value: objectPath.get(equipment, path),
        async onSubmit (value) {
          // Set value of path on cloned equipment
          objectPath.set(equipment, path, value);

          // Create mutations
          const mutations = mutationsLib.create([path], vm.equipment.formatBody('update'), equipment.formatBody('update'));
          if (mutations.length === 0) {
            return;
          }

          // Update equipment
          let equipmentDocument = null;
          if (vm.$auth.isResourceFromIndex('users')) {
            equipmentDocument = await vm.$ws.patch(`${Equipment.uri}/${vm.equipment._meta.guid}/specifications`, { body: mutations });
          }

          vm.$eventhub.emit(`document:${equipmentDocument._meta.index}:${equipmentDocument._meta.guid}:update`, equipmentDocument);
        },
      });
    },

    modifySunbedSpecifications () {
      const vm = this;
      vm.$eventhub.emit('modals:equipment:modify-sunbed-specifications:open', {
        async onSubmit (equipment) {
          const mutations = mutationsLib.create(['model', 'type', 'specifications.options', 'specifications.colors'], vm.equipment.formatBody('update'), equipment.formatBody('update'));
          if (mutations.length === 0) {
            return;
          }

          let equipmentDocument = null;
          if (vm.$auth.isResourceFromIndex('users')) {
            equipmentDocument = await vm.$ws.patch(`${Equipment.uri}/${vm.equipment._meta.guid}/specifications`, {
              body: mutations,
            });
          }

          vm.$eventhub.emit(`document:${equipmentDocument._meta.index}:${equipmentDocument._meta.guid}:update`, equipmentDocument);
        },
      });
    },

    transformCompanies (companies = []) {
      // return (companies || []).filter((company) => company._meta.guid !== companyLib.current._meta.guid, []);
      return companies;
    },
  },
};
</script>
